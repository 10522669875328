<template>
	<div class="page" v-if="detailInfo">
		<van-nav-bar left-arrow @click-left="onClickLeft">
			<template #title>
				<span>{{
					`${bizNo}-${detailInfo.homeBasicDetailDTO.customerName}`
				}}</span>
			</template>
		</van-nav-bar>
		<van-tabs
			class="tabs"
			v-model="currentTab"
			line-height="1px"
			line-width="4em"
			title-inactive-color="#41444E"
			title-active-color="#3C86FF"
		>
			<van-tab title="客户信息" name="basic">
				<CardBasic
					:detailInfo="detailInfo"
					:bizNo="bizNo"
					:readonly="!!readonly"
					:addrData="addrData"
				></CardBasic>
			</van-tab>
			<van-tab title="家访信息" name="visit">
				<CardVisit
					:detailInfo="detailInfo"
					:bizNo="bizNo"
					:homeNo="homeNo"
					:readonly="!!readonly"
					:addrData="addrData"
				></CardVisit>
			</van-tab>
			<van-tab title="影像资料" name="attach">
				<CardAttach
					:detailInfo="detailInfo"
					:homeNo="homeNo"
					:bizNo="bizNo"
					:readonly="!!readonly"
				></CardAttach>
			</van-tab>
		</van-tabs>
	</div>
</template>
<script>
import Api from "@/api/caseHandle";
import ApiDetail from "@/api/caseDetail";
import userApi from "@/api/user";
import { Toast } from "vant";

export default {
	components: {
		CardBasic: () => import("./components/CardBasic"),
		CardVisit: () => import("./components/CardVisit"),
		CardAttach: () => import("./components/CardAttach")
	},
	data() {
		return {
			homeNo: this.$route.params.homeNo,
			bizNo: this.$route.query.bizNo || "",
			readonly: this.$route.query.readonly || "",
			currentTab: this.$route.query.currentTab || "basic",
			addrData: {
				permanentzAddress: {},
				liveAddress: {},
				fcAddr: {},
				unitAddress: {}
			},
			detailInfo: null
		};
	},
	watch: {
		currentTab: {
			immediate: true,
			handler(val) {
				const targetUrl = this.$router.resolve({
					name: this.$route.name,
					params: this.$route.param,
					query: {
						...this.$route.query,
						currentTab: val
					}
				}).href;
				history.replaceState(null, "", targetUrl);
				if (["basic", "visit"].includes(this.currentTab)) {
					this.addrInfo();
				}
			}
		}
	},
	methods: {
		// 加载详情
		async loadDetail() {
			const loading = Toast.loading();
			try {
				const res = await Api.findDetailByHomeNo(this.homeNo);
				this.detailInfo = res.data;
				loading.close();
			} catch (error) {
				Toast.fail(error);
			}
		},

		// 客户居住地相关信息
		async addrInfo() {
			try {
				const addrs = await ApiDetail.addrInfo({
					bizNo: this.bizNo
				});
				const res = await userApi.skyMapLocationsList({
					projectCodeEq: this.bizNo
				});
				let list = res.data || [];
				let data = {
					permanentzAddress: list.find((item) => item.type === "HJ") || {},
					liveAddress: list.find((item) => item.type === "JZ") || {},
					fcAddr: list.find((item) => item.type === "FC") || {},
					unitAddress: list.find((item) => item.type === "DW") || {}
				};
				// 如果天地图地址无效，就是用原始数据地址
				for (let k in data) {
					data[k].address = data[k].address || addrs?.data[k];
				}
				this.addrData = data;
			} catch (error) {
				Toast.fail(error);
			}
		},

		initData() {
			this.loadDetail();
			// this.addrInfo();
		}
	},
	mounted() {
		this.initData();
	}
};
</script>
<style lang="less" scoped>
@import "../../assets/css/variables.less";

.page {
	height: 100%;
	display: flex;
	flex-direction: column;

	/deep/ .van-nav-bar {
		.van-nav-bar__title {
			max-width: 80%;
		}
	}
}

.tabs {
	flex: 1;
	overflow-y: auto;

	/deep/ .van-tabs__content {
		flex: 1;

		.van-tab__pane {
			background: @pageBg;
		}
	}

	/deep/ .van-tabs__nav--line {
		justify-content: space-between;
		padding-bottom: 10px;
	}

	/deep/ .van-tabs__line {
		background: #3c86ff;
	}
}
</style>
